<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="px-10 pt-10 pb-4">
        <h3>Switch broadcast?</h3>
        <v-spacer></v-spacer>
        <v-btn color="#ADC7CC" icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <v-card-text class="px-10 pb-10">
          <p class="description-text">You are trying to stop broadcast of <b>{{ leftStream.title }}</b> for this screen and broadcast <b>{{ rightStream.title }}</b> instead</p>
          <v-row class="py-4">
            <v-col cols="5">

              <div class="thumbnail">
                <video id="stream-settings-preview-left" class="video-preview video-js vjs-4-3 vjs-big-play-centered" playsinline muted autoplay></video>
                <close-square class="video-icon"/>
              </div>

            </v-col>
            <v-col cols="2" class="switch-arrow">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col cols="5">

              <div class="thumbnail">
                <video id="stream-settings-preview-right" class="video-preview video-js vjs-4-3 vjs-big-play-centered" playsinline muted autoplay></video>
                <tick-square class="video-icon"/>
              </div>

            </v-col>
          </v-row>
          <v-row class="justify-space-between">
            <v-btn class="ma-3 cancel-button" width="180" height="60" @click="closeDialog">
              Cancel
            </v-btn>
            <v-btn class="ma-3 switch-button" width="180" height="60" color="#EB5757" @click="switchStream">
              Switch
            </v-btn>
          </v-row>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import registerIVSTech from "@/ivstech"
  import videojs from '@/video';
  import CloseSquare from "@/components/admin/stream/icons/CloseSquare";
  import TickSquare from "@/components/admin/stream/icons/TickSquare";

  export default {
    components: {
      CloseSquare,
      TickSquare,
    },
    props: {
      dialog: { type: Boolean, default: false },
      leftStream: { type: Object, required: true },
      rightStream: { type: Object, required: true },
      // room: { type: Object, required: true },
    },
    data() {
      return {
        playerLeft: null,
        playerRight: null,
      }
    },
    methods: {
      async loadPlayers() {
        registerIVSTech(videojs, { })

        this.playerLeft = videojs(`#stream-settings-preview-left`, { techOrder: ["AmazonIVS"] }, () => {
          this.playerLeft.src(this.leftStream.keys.playbackUrl);
          this.playerLeft.muted(true);
          this.$forceUpdate();
        });
        this.playerRight = videojs(`#stream-settings-preview-right`, { techOrder: ["AmazonIVS"] }, () => {
          this.playerRight.src(this.rightStream.keys.playbackUrl);
          this.playerRight.muted(true);
          this.$forceUpdate();
        });
      },

      closeDialog() {
        this.$emit("close-dialog");
      },

      switchStream() {
        this.$emit('switch');
      },
    },

    watch: {
      dialog(value) {
        if (value) {
          this.$nextTick(()=>{
            this.loadPlayers();
          });
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.thumbnail {
  border: 1px solid rgba(173, 199, 204, 0.5);
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
}

.video-preview {
  border-radius: 4px;
}

.switch-arrow {
  text-align: center;
  align-self: center;
}

.description-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;

  align-items: center;

  color: #252733;
}

.cancel-button {
  color: #EB5757;
}

.switch-button {
  color: #FFFFFF;
}

.video-icon {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
}

</style>
