var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{class:`${_vm.expanded ? 'expanded-view' : 'reduced-view'}`,attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v(_vm._s(_vm.expanded ? "mdi mdi-chevron-up" :"mdi mdi-chevron-down"))]),_vm._v(" "+_vm._s(_vm.server.name)+" ")],1)],1),(_vm.expanded)?_c('v-row',{staticClass:"tile-view",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-sheet',{staticStyle:{"background":"none"}},[_c('div',{staticClass:"px-10"},[_c('v-chip-group',{staticClass:"left-block",attrs:{"column":"","multiple":""},model:{value:(_vm.server.selectedRooms),callback:function ($$v) {_vm.$set(_vm.server, "selectedRooms", $$v)},expression:"server.selectedRooms"}},_vm._l((_vm.server.rooms),function(room){return _c('v-chip',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                  content: _vm.getChipToolTip(room),
                  delay: { show: 500, hide: 100 }
              }),expression:"{\n                  content: getChipToolTip(room),\n                  delay: { show: 500, hide: 100 }\n              }",modifiers:{"bottom":true}}],key:room.guid,class:room.status || 'available',staticStyle:{"width":"130px","justify-content":"center"},attrs:{"label":""},on:{"click":function($event){return _vm.selectRoom(room)}}},[_vm._v(" "+_vm._s(room.caption)+" ")])}),1)],1)])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-sheet',{staticStyle:{"background":"none"}},[_c('div',{staticClass:"px-10"},[_c('v-chip-group',{staticClass:"right-block",attrs:{"column":"","multiple":""}},_vm._l((_vm.server.rooms),function(room){return _c('v-chip',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                content: _vm.getChipToolTip(room),
                delay: { show: 500, hide: 100 }
              }),expression:"{\n                content: getChipToolTip(room),\n                delay: { show: 500, hide: 100 }\n              }",modifiers:{"bottom":true}}],key:room.guid,class:room.status || 'available',staticStyle:{"width":"130px","justify-content":"center"},attrs:{"label":"","disabled":!room.status || room.status === 'occupied'},on:{"click":function($event){return _vm.selectRoom(room)}}},[_vm._v(" "+_vm._s(room.caption)+" ")])}),1)],1)])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
          content: 'Click to arrange all available screens',
          delay: { show: 500, hide: 100 }
        }),expression:"{\n          content: 'Click to arrange all available screens',\n          delay: { show: 500, hide: 100 }\n        }",modifiers:{"bottom":true}}],staticClass:"divider-button",attrs:{"rounded":"","width":"36px","min-width":"36px"},on:{"click":_vm.addAllRooms}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }