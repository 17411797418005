<template>
  <article class="admin-page-wrapper admin-stream">
    <header>
      <v-row class="admin-header-nav">
        <admin-tabs />
        <v-spacer></v-spacer>
        <v-btn ml-4 dark large color="#1EAFC1" @click="createStream" :loading="streamCreating">+ Add new stream</v-btn>
        <v-autocomplete
          v-model="select"
          :filter="filterItems"
          :items="allStreams.filter(s => s.arn !== stream.arn)"
          class="header-search pl-5"
          hide-details
          style="max-width: 205px;"
          label="Search for stream"
          solo
          prepend-inner-icon="mdi-magnify"
          append-icon=""
        >
          <template v-slot:selection="{ item }">
            <span>{{ item.title }}</span>
          </template>

          <template slot="item" slot-scope="{ item }">
            <p>{{ item.title }}</p>
          </template>
        </v-autocomplete>
      </v-row>
    </header>
    <main class="stream-settings">
      <v-progress-linear v-if="streamLoading" indeterminate color="teal accent-4" />
      <div v-else class="content-wrapper">
        <v-row dense no-gutters>
          <v-col cols="5">
            <header class="mb-4">
              <inline-text-field :value="stream.title" @save="changeTitle"></inline-text-field>
            </header>
            <h4>Location screens overview</h4>
            <p class="locations-description">The entire space is divided into zones, in each of which you can start broadcasting</p>
            <v-img :src="require('@/assets/stream-settings/world-map.svg')"></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6">
            <div class="thumbnail">
              <video id="stream-settings-preview" class="video-preview video-js vjs-4-3 vjs-big-play-centered" playsinline autoplay></video>
            </div>
            <v-icon v-if="muted" class="mt-2 mute-button" style="cursor: pointer" @click="muteStream">mdi-volume-mute</v-icon>
            <v-icon v-else class="mt-2 mute-button" style="cursor: pointer" @click="muteStream">mdi-volume-high</v-icon>
          </v-col>
        </v-row>
        <v-divider class="my-10"></v-divider>
        <v-row no-gutters class="screens-header">
          <v-col>
            <p class="title">Available screens</p>
            <p class="description">Those screens are ready for broadcasting<br>Click to arrange screen</p>
          </v-col>
          <v-col>
          <p class="title" style="vertical-align: middle;">
            Stream is now live here
            <v-img style="display: inline-block; margin-left: 4px;" src="@/assets/stream-settings/live-stream-circle.svg" width="15px" />
          </p>
          <p class="description">Those screens are broadcasting this stream right now<br>Click to remove screen</p>
        </v-col>
        </v-row>
        <div class="stream-description">
          <v-row no-gutters class="screens-header">
          <v-col cols="3">
            <p class="title">Server status</p>
            <p class="description">Short description</p>
          </v-col>
          <v-col cols="3" class="px-2">
            <p class="status">
              <v-icon color="#96ADE3">fa fa-square</v-icon>
              Occupied by another stream
            </p>
            <p class="status">
              <v-icon color="#77C090">fa fa-square</v-icon>
              Broadcasting this stream right now
            </p>
          </v-col>
          <v-col cols="3" class="px-2">
            <p class="status">
              <v-icon color="#E8D678">fa fa-square</v-icon>
              Ready to be arranged
            </p>
            <p class="status">
              <v-icon color="#3561CB">fa fa-square</v-icon>
              Available screen
            </p>
          </v-col>
          <v-col cols="3" class="px-2">
            <p class="status">
              <v-icon color="#F5F9FD" style="border: 2px solid #E9EFF4; border-radius: 3px;">fa fa-square</v-icon>
              Occupied screen
            </p>
            <p class="status">
              <v-icon color="#BF5425">fa fa-square</v-icon>
              Ready to be stopped
            </p>
          </v-col>
        </v-row>
        </div>
        <v-progress-linear v-if="servers.length == 0" indeterminate color="teal accent-4" />
        <stream-tile-view
          v-else
          v-for="server in servers"
          :key="server.name"
          :server="server"
          :all-streams="allStreams"
          :current-stream="stream"
          @switch-streams="openSwitchDialog"
          class="my-16"
        />
      </div>
      <div v-if="!streamLoading" class="footer">
<!--        <v-btn large dark color="#EB5757" class="mx-4" width="220px" @click="stopBroadcast" :loading="isLoading">Stop broadcasting</v-btn>-->
        <v-btn large dark color="#EB5757" class="mx-4" width="220px" @click="backToStreams" :loading="isLoading || isSaving"><v-icon>mdi-arrow-left</v-icon>Back to all streams</v-btn>
        <v-btn v-if="servers.length > 0" large dark color="#1EAFC1" class="mx-4" width="220px" @click="save" :loading="isLoading || isSaving">Apply changes</v-btn>
      </div>
    </main>
    <room-occupied-dialog
        :dialog="roomOccupiedDialog"
        :servers="notAvailableRooms"
        @save="saveOccupiedDialog"
        @close-dialog="closeRoomOccupiedDialog"
    />
    <stream-switch-dialog
        :dialog="streamSwitchDialog"
        :right-stream="stream"
        :left-stream="leftStream"
        @close-dialog="closeSwitchDialog"
        @switch="switchStatus"
    />
    <overlayed-dialog
        :dialog="overlayedDialog"
        :rooms="overlayedRooms"
        @close-overlayed-dialog="closeOverlayedDialog"
    />
  </article>
</template>

<script>
import awsAuth from '@/cognito/aws-auth'
import registerIVSTech from "@/ivstech"
import videojs from '@/video';
import request from '@/plugins/request';

import InlineTextField from './InlineTextField.vue';
import StreamTileView from "./StreamTileView";
import AdminTabs from "../AdminTabs";
import {mapActions} from "vuex";
import RoomOccupiedDialog from "./RoomOccupiedDialog";
import StreamSwitchDialog from "./StreamSwitchDialog";
import OverlayedDialog from "./OverlayedDialog";


export default {
  components: {
    InlineTextField,
    StreamTileView,
    AdminTabs,
    RoomOccupiedDialog,
    StreamSwitchDialog,
    OverlayedDialog
  },

  data() {
    return {
      isLoading: false,
      streamLoading: true,
      editable: false,
      availableServers: [],
      servers: [],
      stream: {},
      allStreams: [],
      player: null,

      allStreamsRooms: [],
      allServers: [],
      muted: true,
      updateInterval: null,
      roomOccupiedDialog: false,
      notAvailableRooms: [],
      streamCreating: false,
      select: null,
      isSaving: false,

      leftStream: {},
      currentRoom: {},
      streamSwitchDialog: false,
      overlayedRooms: [],
      overlayedDialog: false,
    }
  },

  async beforeDestroy() {
    if(this.player) {
      this.player.dispose();
    }
    clearInterval(this.updateInterval);
  },


  mounted() {
    this.loadInfo();
  },

  methods: {
    ...mapActions("ui", ["snackbar"]),

    checkStreamStatus() {
      request({ type: 'GET', url: `/aivs/getStreamByArn?arnKey=${this.stream.arn}` })
        .then(res => {
          if (res.data.status === 'created') {
            this.snackbar({
              color: "error",
              icon: "mdi-alert-circle-outline",
              title: `${this.stream.title} was terminated`,
              text: 'Please start it to be able to broadcast to RendezVu',
            });
            clearInterval(this.updateInterval);
            this.stopBroadcast();
            this.backToStreams();
          }
        })
    },
    openSwitchDialog(room) {
      this.leftStream = this.allStreams.find(s => s.rooms.some(r => r.name == room.name));
      this.currentRoom = room;
      this.streamSwitchDialog = true;
    },
    switchStatus() {
      this.currentRoom.status = 'editing-occupied';
      this.closeSwitchDialog();
    },

    closeSwitchDialog() {
      this.leftStream = {};
      this.currentRoom = {};
      this.streamSwitchDialog = false;
    },

    closeOverlayedDialog() {
      this.overlayedRooms = [];
      this.overlayedDialog = false;
    },

    async createStream() {
      this.streamCreating = true;
      const currentDate = new Date();
      const creationDate = currentDate.toLocaleDateString('en-US');

      await request({ method: 'POST', url: '/aivs/create', body: { title: `stream-${this.allStreams.length + 1}`, description: creationDate} })

      this.streamCreating = false;
      this.$router.push('/admin/stream');
    },
    async saveOccupiedDialog(servers) {
      await servers.map(server => {
        server.rooms.map(room => {
          if (room.selected) {
            room.status = 'editing-occupied';

          }
        })
      });
      this.closeRoomOccupiedDialog();
    },

    closeRoomOccupiedDialog() {
      this.notAvailableRooms = [];
      this.roomOccupiedDialog = false;
    },

    async getCurrentRoomsStatusAndBlock() {
      if (this.isLoading) {
        return;
      }

      let allStreamsData = await request({ method: 'GET', url: '/aivs/channels-block'});
      let otherStreams = allStreamsData.data.filter(stream => stream.arn !== this.stream.arn);
      let currentStream = allStreamsData.data.find(s => s.arn == this.stream.arn);

      this.allStreams = allStreamsData.data;
      this.allStreamsRooms = await allStreamsData.data.map(stream => {
        return stream.rooms.map(room => {
          return { ...room, ...{ streamArn: stream.arn, streamTitle: stream.title }}
        })
      }).flat(1);



      let otherStreamRooms = otherStreams.map(stream => stream.rooms).flat(1);

      let filteredServers = this.servers.filter(server => {
        server.roomsNowOccupied = server.rooms.filter(room => {

          if (currentStream.rooms.some(r => r.name == room.name)) {
            // now streaming
            switch (room.status) {
              case '':
              case 'available':
              case 'editing':
              case 'editing-occupied':
              case 'occupied':

                room.status = 'streaming';
                return false;
              default:
                return false;
            }
          }
          else if (!otherStreamRooms.some(r => r.name == room.name)) {
            // room is no longer occupied
            switch (room.status) {
              case 'occupied':
                room.status = '';
                break;
              case 'editing-occupied':
                room.status = 'editing';
                break;
              case 'streaming':
              case 'ready-to-stop':
                room.status = 'available';
                break;
              default:
                break;
            }
            return false;

          } else {
            // room occupied by another stream
            switch (room.status) {
              case '':
              case 'available':
                room.status = 'occupied';
                return false;
              case 'editing':
                room.status = 'occupied';
                return true;
              case 'streaming':
                room.status = 'occupied';
                return false;
              default:
                return false;
            }
          }
        });

        // console.log(`${server.name} - ${server.roomsNowOccupied.length > 0}`);
        return server.roomsNowOccupied.length > 0;
      })

      if (filteredServers.length > 0) {
        this.notAvailableRooms = filteredServers;
        this.roomOccupiedDialog = true;
      }
    },

    async getCurrentRoomsStatus() {
      // console.log('getCurrentRoomsStatus...');
        // console.log('busy...aborting...');
      if (this.isLoading || this.streamSwitchDialog || this.roomOccupiedDialog || this.overlayedDialog) {
        return;
      }
      // console.log('processing...');

      let allStreamsData = await request({ method: 'GET', url: '/aivs/channels'});
      let otherStreams = allStreamsData.data.filter(stream => stream.arn !== this.stream.arn);
      this.overlayedRooms = [];

      let currentStream = allStreamsData.data.find(s => s.arn == this.stream.arn);
      this.allStreams = allStreamsData.data;
      this.allStreamsRooms = await allStreamsData.data.map(stream => {
        return stream.rooms.map(room => {
          return { ...room, ...{ streamArn: stream.arn, streamTitle: stream.title }}
        })
      }).flat(1);

      let otherStreamRooms = otherStreams.map(stream => stream.rooms).flat(1);

      let filteredServers = this.servers.filter(server => {
        server.roomsNowOccupied = server.rooms.filter(room => {

          if (currentStream.rooms.some(r => r.name == room.name)) {
            // now streaming
            switch (room.status) {
              case '':
              case 'available':
              case 'editing':
              case 'editing-occupied':
              case 'occupied':

                room.status = 'streaming';
                return false;
              default:
                return false;
            }
          }
          else if (!otherStreamRooms.some(r => r.name == room.name)) {
            // room is no longer occupied
            switch (room.status) {
              case 'occupied':
                room.status = '';
                break;
              case 'editing-occupied':
                room.status = 'editing';
                break;
              case 'streaming':
              case 'ready-to-stop':
                room.status = 'available';
                break;
              default:
                break;
            }
            return false;

          } else {
            // room occupied by another stream
            switch (room.status) {
              case '':
              case 'available':
                room.status = 'occupied';
                return false;
              case 'editing':
                room.status = 'occupied';
                return true;
              case 'streaming':
                room.status = 'occupied';
                // add overlayed room to array to display later in alert
                this.overlayedRooms.push(room);
                return false;
              default:
                return false;
            }
          }
        });

        return server.roomsNowOccupied.length > 0;

      })

      if (filteredServers.length > 0) {
        this.notAvailableRooms = filteredServers;
        this.roomOccupiedDialog = true;
        // return filteredServers;
      }

      if (this.overlayedRooms.length > 0) {
        this.overlayedDialog = true;
      }
    },

    backToStreams() {
      this.$router.push('/admin/stream');
    },
    muteStream() {
      this.muted = !this.muted;
      this.player.muted(this.muted);
    },

    async loadInfo() {
      await this.loadStream();
      await this.loadPlayer();
      await this.fetchServers();
      this.updateInterval = setInterval(() => {
        // this.checkStreamStatus();
        this.getCurrentRoomsStatus();
      }, 5000);
    },
    async loadPlayer() {
      registerIVSTech(videojs, {})

      this.player = videojs(`#stream-settings-preview`, {techOrder: ["AmazonIVS"]}, () => {
        this.player.src(this.stream.keys.playbackUrl);
        this.player.muted(true);
        this.$forceUpdate();
      });
    },

    async loadStream() {
      let allServersResponse = await awsAuth.getAllServers();
      this.allServers = allServersResponse.Servers;

      let streamData = await request({ method: 'GET', url: `/aivs/getChannelByArn?arnKey=${this.$route.query.arn}`});


      if (!streamData.data || streamData.data.status == 'deleted') {
        console.log('No streamData or deleted...');

        if (this.$route.params.caption) {
          this.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: "Error",
            text: `Couldn't find ${this.$route.params.caption}`,
            timeout: -1,
          });
        }

        await this.$router.push('/admin/stream/');

        return;
      }

      this.stream = streamData.data;
      this.streamLoading = false;
    },

    getRoomsStatus(server, creds) {
      return creds.map(room => {

        let status = '';
        if (this.stream.rooms.some(r => r.name == room.Name)) {
          status = 'streaming';
        } else if (this.allStreamsRooms.some(r => r.name == room.Name)) {
          status = 'occupied';
        }

        return {
          serverAddress: server.ServerAddress,
          serverName: server.Caption,
          name: room.Name,
          guid: room.Guid,
          caption: room.Caption,
          conferenceBroadcastType: room.ConferenceBroadCastType,
          isStreaming: room.IsStreaming,
          agoraPlayerId: room.AgoraPlayerId,
          channelId: room.ChannelId,
          token: room.Token,
          status: status,
        };

      });
    },

    async fetchServers() {
      const self = this;

      let allStreamsData = await request({ method: 'GET', url: '/aivs/channels'});
      this.allStreams = allStreamsData.data;
      this.allStreamsRooms = await allStreamsData.data.map(stream => {
        return stream.rooms.map(room => {
          return { ...room, ...{ streamArn: stream.arn, streamTitle: stream.title }}
        })
      }).flat(1);

      self.availableServers = await Promise.all(this.allServers.map(async (server) => {
        const creds = await awsAuth.getRoomsCredentials(server.ServerAddress);
        const status = await awsAuth.getRoomsStatus(server.ServerAddress);

        const mergeByGuid = (a1, a2) =>
          a1.map(itm => ({
            ...a2.find((item) => (item.Guid === itm.Guid)),
            ...itm,
            ...{ Name: server.Caption +  itm.ChannelId}
          }));

        const merged = mergeByGuid(creds, status);

        server.rooms = await self.getRoomsStatus(server, merged);

        return server;

      }));

      this.updateServers();
    },

    updateServers() {
      this.servers = this.availableServers
        .map(server => {
          return {
            name: server.Caption,
            port: server.ServerPort,
            address: server.ServerAddress,
            rooms: server.rooms,
          };
        });

    },


    async save() {
      this.isSaving = true;

      await this.getCurrentRoomsStatusAndBlock();

      this.isLoading = true;

      let allPlayers = await request({ method: 'GET', url: '/agora/players' });
      let otherStreamRooms = this.allStreamsRooms.filter(room => room.streamArn !== this.stream.arn);

      let roomsToStart = this.servers.map(server => {
        return server.rooms.filter(r => r.status.includes('editing'));
      }).flat(1);

      let roomsToStop = this.servers.map(server => {
        // return server.rooms.filter(r => r.status === 'ready-to-stop');
        return server.rooms.filter(r => r.status === 'ready-to-stop' || r.status === 'editing-occupied');
      }).flat(1);

      let roomsStatus = (await Promise.all(this.allServers.map(async server => {

        const status = await awsAuth.getRoomsStatus(server.ServerAddress);
        status.map(s => {
          s.ServerAddress = server.ServerAddress;
        });
        return status;
      }))).flat(1);

      let keyNoteRooms = roomsStatus.filter(r => r.IsStreaming && r.ConferenceBroadcastType === 'Broadcast');

      roomsToStart.filter(r => keyNoteRooms.find(k => k.Guid == r.guid && k.ServerAddress == r.serverAddress)).map(async room => {
        await request({ method: 'POST', url: '/agora/stopPlayer', body: { address: room.serverAddress, roomGuid: room.guid} });
      });

      await Promise.all(roomsToStop.map(async room => {
        await request({ method: 'POST', url: '/agora/stopPlayer', body: { address: room.serverAddress, roomGuid: room.guid} });

        if (allPlayers.data.some(p => p.name == room.name)) {
          let id = allPlayers.data.find(p => p.name == room.name).id;
          await request({ method: 'DELETE', url: `/agora/delete?id=${id}`});
        }
      }));

      let roomsToEdit = otherStreamRooms.filter(room => roomsToStop.some(r => r.name === room.name));

      let streamsToEdit = roomsToEdit.reduce((group, room) => {
        const { streamArn } = room;
        group[streamArn] = group[streamArn] ?? [];
        group[streamArn].push(room);
        return group;
      }, {});

      Object.entries(streamsToEdit).forEach(async ([arn, rooms]) => {

        await request({
          method: 'POST',
          url: '/aivs/updateRooms',
          body: { arn: arn, filterRooms: rooms }
        });
      });

      let currentRooms = this.servers.map(server => {
        return server.rooms.filter(r => r.status.includes('editing') || r.status == 'streaming');
      }).flat(1);

      await request({
        method: 'POST',
        url: '/aivs/update',
        body: {
          arn: this.stream.arn,
          title: this.stream.title,
          toStart: roomsToStart,
          rooms: currentRooms,
        }
      });

      this.stream.rooms = currentRooms;

      await this.fetchServers();
      this.isLoading = false;
      this.isSaving = false;

    },

    changeTitle(value) {
      this.isLoading = true;
      this.stream.title = value;

      this.updateStream(this.stream);
    },

    updateStream(stream) {
      request({ method: 'POST', url: '/aivs/update', body: { arn: stream.arn, title: stream.title } })
        .then(()=> {
          this.isLoading = false;
          this.$router.push({ path: `/admin/stream/${stream.title}`, query: { arn: stream.arn } });
        });
    },

    async stopBroadcast() {

      this.isLoading = true;
      let allPlayers = await request({ method: 'GET', url: '/agora/players' });

      await this.stream.rooms.map(async room => {
        await request({ method: 'POST', url: '/agora/stopPlayer', body: { address: room.serverAddress, roomGuid: room.guid} });

        if (allPlayers.data.some(p => p.name == room.name)) {
          let id = allPlayers.data.find(p => p.name == room.name).id;
          request({ method: 'DELETE', url: `/agora/delete?id=${id}`});
        }
      });
      await request({ method: 'POST', url: `/aivs/stopBroadcasting?arnKey=${this.stream.arn}` });

      this.stream.rooms = [];

      await this.fetchServers();
      this.isLoading = false;
    },

    filterItems(item, queryText) {
      return item.title.toLowerCase().indexOf(queryText.toLowerCase()) > -1 && item.arn !== this.stream.arn;
    }
  },
  watch: {
    select(val) {
      if (val) {
        let url = encodeURI(`/admin/stream/${val.title}?arn=${val.arn}`);
        this.$router.push({
          path: url,
          force: true
        });
        this.streamLoading = true;
        this.loadInfo();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
}

.mute-button {
  cursor: pointer;
  float: right;
}
.stream-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;

  color: #185FBF;
}

.locations-description {
  margin: 20px 20px 34px 0;
  font-size: 13px;
  line-height: 140%;

  color: #99B4B9;
}

.screens-header {
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;

    margin-bottom: 15px;
    color: #252733;
  }

  .description {
    font-size: 13px;
    line-height: 140%;

    margin-bottom: 0;

    color: #99B4B9;
  }
}

.v-btn {
  font-weight: 500;
  font-size: 13px !important;
  line-height: 100%;

  text-transform: unset;
}

.v-icon:focus::after {
  opacity: 0!important;
}

.stream-settings {
  position: relative;

  background: #FFFFFF;
  border-radius: 6px;

  padding: 2rem;

  section {
    margin: 2rem 0;
  }

  footer {
    display: flex;

    flex-flow: row nowrap;

    justify-content: center;
    align-items: center;
  }
}

.thumbnail {

  border: 1px solid rgba(173, 199, 204, 0.5);
  border-radius: 4px;

  margin: 0 auto;
}

.server-list {
  list-style-type: none;

  max-height: 150px;

  overflow-y: scroll;

  margin: 1rem 0;
  padding: 0;

  .v-label {
    margin-top: 1rem !important;
  }

  li {
    display: flex;
    flex-flow: row nowrap;

    align-items: flex-end;
  }
}

.server-header {
  font-weight: 500;
  font-size: 14px;

  line-height: 100%;

  color: #185FBF;
}

.server-hint {
  font-weight: normal;
  font-size: 13px;

  line-height: 100%;

  color: #99B4B9;
}

.info-caption {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;

  line-height: 140%;

  color: #99B4B9;
}
.stream-description {
  margin-top: 3rem;

  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;

    color: #252733;
  }

  .description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;

    color: #99B4B9;
  }

  .status {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;

    color: #252733;

    .v-icon {
      font-size: 15px;
      width: 15px;
      height: 15px;
      border: 2px solid currentColor;
      border-radius: 3px;
    }
  }
}

.admin-stream > main {
    display: flex;

    flex-flow: column nowrap;
    align-items: center;

    justify-content: center;
}
</style>
