<template>
  <div>
    <v-row justify="center" :class="`${expanded ? 'expanded-view' : 'reduced-view'}`">
      <v-col cols="12">
        <v-icon @click="expanded = !expanded">{{ expanded ? "mdi mdi-chevron-up" :"mdi mdi-chevron-down" }}</v-icon> {{ server.name }}
      </v-col>
    </v-row>
    <v-row justify="center" class="tile-view" v-if="expanded">
      <v-col cols="6">
        <v-sheet style="background: none; ">
          <div class="px-10">
            <v-chip-group
              column
              v-model="server.selectedRooms"
              multiple
              class="left-block"
            >
              <v-chip
                label
                v-for="room in server.rooms"
                :key="room.guid"
                :class="room.status || 'available'"
                style="width: 130px; justify-content: center"
                @click="selectRoom(room)"
                v-tooltip.bottom="{
                    content: getChipToolTip(room),
                    delay: { show: 500, hide: 100 }
                }"
              >
                {{ room.caption }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-sheet>
      </v-col>
      <v-divider
        vertical
      />
      <v-col cols="6">
        <v-sheet style="background: none;">
          <div class="px-10">
            <v-chip-group
              column
              multiple
              class="right-block"
            >
              <v-chip
                label
                v-for="room in server.rooms"
                :key="room.guid"
                :class="room.status || 'available'"
                :disabled="!room.status || room.status === 'occupied'"
                style="width: 130px; justify-content: center;"
                @click="selectRoom(room)"
                v-tooltip.bottom="{
                  content: getChipToolTip(room),
                  delay: { show: 500, hide: 100 }
                }"
                >
                  {{ room.caption }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-sheet>
      </v-col>
        <v-btn
          class="divider-button"
          rounded
          width="36px"
          min-width="36px"
          @click="addAllRooms"
          v-tooltip.bottom="{
            content: 'Click to arrange all available screens',
            delay: { show: 500, hide: 100 }
          }"
        >
          <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>


<script>
  export default {
    name: "StreamTileView.vue",
    components: {
      // StreamSwitchDialog
    },
    props: {
      server: { type: Object, required: true },
      allStreams: { type: Array, required: true },
      currentStream: { type: Object, required: true },
    },
    data() {
      return {
        switchDialog: false,
        expanded: true,
        leftStream: {},
        currentRoom: {},

      }
    },
    computed: {

    },
    methods: {
      getChipToolTip(room) {
        let description = '';
        let tappedRoomStream = this.allStreams.find(s => s.rooms.some(r => r.name == room.name));
        let streamName = tappedRoomStream ? tappedRoomStream.title : 'STREAM';

        switch (room.status) {
          case 'streaming':
            description = 'Your stream is live<br> on this screen right now';
            break;
          case 'ready-to-stop':
            description = 'Ready for rearrangement<br>Click on <b>Apply changes</b><br>button below to execute'
            break;
          case 'editing':
            description = 'Ready for rearrangement<br>Click on <b>Apply changes</b><br>button below to execute'
            break;
          case 'occupied':

            description = 'Occupied by<br><b>' + streamName + '</b><br>Click to rearrange'
            break;
          case 'editing-occupied':
            description = 'Ready for rearrangement<br>Click on <b>Apply changes</b><br>button below to execute'

            break;
          default:
            description = 'Available for streaming';
        }
        return description;
      },

      selectRoom(room) {
        switch (room.status) {
          case 'streaming':
            room.status = 'ready-to-stop';
            break;
          case 'ready-to-stop':
            room.status = 'streaming';
            break;
          case 'editing':
            room.status = '';
            break;
          case 'occupied':
            this.showSwitchDialog(room);
            break;
          case 'editing-occupied':
            room.status = 'occupied';
            break;
          default:
            room.status = 'editing';

        }
      },

      closeSwitchDialog() {
        this.leftStream = {};
        this.currentRoom = {};
        this.switchDialog = false;
      },

      showSwitchDialog(room) {
        this.$emit("switch-streams", room);
      },

      addAllRooms() {
        this.server.rooms.forEach(room => {

          switch (room.status) {
            case '':
              room.status = 'editing';
              break;
            case 'available':
              room.status = 'editing';
              break;
            case 'ready-to-stop':
              room.status = 'streaming';
              break;
          }
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
.expanded-view {
  background-color: #F1F5F9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.reduced-view {
  background-color: #F1F5F9;
  border-radius: 4px;
}

.tile-view {
  align-items: center;
  background-color: rgb(251, 252, 253);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.divider-button {
  position: absolute;
  color: #ADC7CC;
}

.divider-button:hover {
  background-color: #3561CB;
}

.left-block {
  .available {
    background-color: #3561CB !important;
    color: #FFFFFF;
  }
  .streaming {
    background-color: #F7F9FB !important;
    color: #99B4B9 !important;
    border: 1px solid #F0F3F7;
  }
  .editing {
    background-color: #E8D678 !important;
    color: #FFFFFF;
  }
  .occupied {
    background-color: #96ADE3 !important;
    color: #FFFFFF;
  }
  .ready-to-stop {
    background-color: #BF5425 !important;
    color: #FFFFFF;
    opacity: 1;
  }
  .editing-occupied {
    background-color: #E7AD84 !important;
    color: #FFFFFF;
  }
}

.right-block {
  .available {
    opacity: 0;
    background-color: #F7F9FB !important;
    color: #F7F9FB;
  }
  .streaming {
    background-color: #77C090 !important;
    color: #FFFFFF !important;
  }
  .editing {
    background-color: #E8D678 !important;
    color: #FFFFFF;
  }
  .occupied {
    opacity: 0;
    background-color: #F7F9FB !important;
    color: #F7F9FB;
  }
  .editing-occupied {
    background-color: #E8D678 !important;
    color: #FFFFFF;
  }
  .ready-to-stop {
    background-color: #BF5425 !important;
    color: #FFFFFF;
  }
}

.v-chip--active::before {
  opacity: 0 !important;
}

.v-tooltip__content {
  padding: 15px;
  opacity: 1!important;
}

</style>

<style>
.tooltip {
  background-color: #FFFFFF !important;
  margin-top: 10px;
  border-radius: 4px;
}

.tooltip-inner {
  padding: 15px;
  color: #99B4B9;
  font-size: 12px;
  line-height: 135%;
}

.v-slide-group__content {
  justify-content: center;
}
</style>
