<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pa-10">
      <v-card-title>
        <error-icon class="mr-3"/>
        <h3>Pay attention</h3>
        <v-spacer></v-spacer>
        <v-btn color="#ADC7CC" icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="server-list-description">These rooms were occupied by someone else.<br />You still can start broadcast to these rooms.<br /><br />Check rooms to continue.</p>
        <div v-for="server in servers" :key="server.address" class="mt-6 px-4">
          <p class="server-list-server">{{ server.name.toUpperCase() }}</p>
            <v-checkbox
              v-for="room in server.roomsNowOccupied"
              :label="room.caption"
              :key="room.name"
              v-model="room.selected"
              hide-details
              class="shrink mr-0 mt-0">
            </v-checkbox>
        </div>
      </v-card-text>
      <v-row class="justify-space-between mt-8 mb-2">
        <v-btn x-large dark color="#EB5757" class="mx-4" width="220px" @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn x-large dark color="#1EAFC1" class="mx-4" width="220px" @click="save">
          Save
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorIcon from './icons/ErrorIcon.vue';

  export default {
    components: {
      ErrorIcon
    },
    props: {
      dialog: { type: Boolean, default: false },
      servers: { type: Array, required: true },
    },
    data() {
      return {
      }
    },
    methods: {
      closeDialog() {
        this.$emit("close-dialog");
      },
      save() {
        this.$emit("save", this.servers);
      },
    }
  }

</script>

<style scoped>
.server-list-server {
  font-weight: 900;
  margin-bottom: 5px;
}

.server-list-room {
  font-weight: 400;
}

.server-list-description {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;

  color: #252733;
}

>>> .v-label {
  top: 0 !important;
  transform: translateY(0%);
}

</style>
