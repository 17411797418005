<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="px-10 pt-10 pb-4">
        <h3>Please note</h3>
        <v-spacer></v-spacer>
        <v-btn color="#ADC7CC" icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-10 pb-10">
        <p class="description-text">Your broadcast was interrupted by another stream</p>
        <div v-for="(server, index) in servers" :key="index" class="mt-6 px-4">
          <h3>{{ server.toUpperCase() }}</h3>
            <li v-for="room in rooms.filter(r => r.serverName === server)" :key="room.name">{{ room.caption }}</li>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  components: {},
  props: {
    dialog: {type: Boolean, default: false},
    rooms: {type: Array, required: true},
  },
  data() {
    return {
    }
  },
  computed: {
    servers() {
      return this.rooms.map(room => room.serverName).filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close-overlayed-dialog");
    },
  },
}
</script>

<style lang="scss" scoped>
.description-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;

  align-items: center;

  color: #252733;
}
</style>
